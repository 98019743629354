import { NgModule } from '@angular/core';
import { ScanViewsComponent } from './scan-views.component';
import { MatTableModule, MatPaginatorModule, MatSortModule, 
  MatInputModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';  
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';



@NgModule({
  declarations: [ScanViewsComponent],
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    CommonModule,
  ],
  exports: [ScanViewsComponent],
})
export class ScanViewsComponentModule {}
