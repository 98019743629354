import { Http, Headers, RequestOptions } from "@angular/http";
// import { Ingredient } from "./ingredient/ingredient.component";
import { Injectable } from "@angular/core";
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { getAttrsForDirectiveMatching } from "@angular/compiler/src/render3/view/util";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  url = "https:///api.saveeat.co";
  // url = "http://localhost:3000";
  authToken = "";

  constructor(private http: Http) {}

  logout() {
    // tslint:disable-next-line: quotemark
    this.authToken = "";
    // tslint:disable-next-line: quotemark
    localStorage.setItem("token", "");
  }

  setAuthToken(token: string) {
    localStorage.setItem("token", token);
    this.authToken = token;
  }

  getToken() {
    return localStorage.getItem("token");
  }

  getAdminIngredients(
    search: string,
    admin: boolean = true,
    page: number = 0,
    sort: string = "id"
  ) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(
      this.url +
        "/admin_ingredients?limit=100&adminonly=" +
        admin +
        "&search=" +
        search +
        "&page=" +
        page +
        "&sort=" +
        sort,
      options
    );
  }

  getThemes() {
    return this.http.get(this.url + "/admin_themes");
  }

  addRecipe(body) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(this.url + "/recipe/admin_add", body, options);
  }

  editRecipe(body) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(this.url + "/recipe/admin_edit", body, options);
  }

  deleteRecipe(id) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/recipe/admin_delete/",
      { id: id, secret: "secret_saveeat" },
      options
    );
  }

  getIngredient(id) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/admin/ingredient/" + id, options);
  }

  authenticate(email, password) {
    return this.http.post(
      this.url + "/admin/authenticate",
      { email: email, password: password },
      {}
    );
  }

  addIngredient(
    name,
    picture,
    barcode,
    unity,
    quantity,
    place,
    admin,
    picture_file_name,
    day_expiration,
    shelf,
    brand
  ) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/admin/ingredient/add",
      {
        ingredient: {
          picture: picture,
          name: name,
          barcode: barcode,
          unity: unity,
          product_quantity: quantity,
          place: place,
          admin: admin,
          picture_file_name: picture_file_name,
          day_expiration: day_expiration,
          shelf_id: shelf,
          brand: brand
        }
      },
      options
    );
  }

  deleteIngredient(id) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/admin/ingredient/delete",
      { id: id },
      options
    );
  }

  editIngredient(
    id,
    name,
    picture,
    barcode,
    unity,
    quantity,
    place,
    admin,
    picture_file_name,
    day_expiration,
    brand,
    user_added,
    shelf_id
  ) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/admin/ingredient/edit",
      {
        id: id,
        picture: picture,
        name: name,
        barcode: barcode,
        unity: unity,
        product_quantity: quantity,
        place: place,
        admin: admin,
        picture_file_name: picture_file_name,
        day_expiration: day_expiration,
        brand: brand,
        user_added: user_added,
        shelf_id: shelf_id
      },
      options
    );
  }

  getSessions() {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/statistics/sessions.json", options);
  }

  getScanStatistics(duration: number = 1 ) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/statistics/scans.json?since=" + duration, options);
  }

  getRecipeViews() {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/statistics/recipes.json", options);
  }

  getLeaves() {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/statistics/leaves.json", options);
  }

  getRegistrations() {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/statistics/registrations.json", options);
  }

  getUserStats() {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/statistics/users_stats.json", options);
  }

  searchRecipes(search) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(
      this.url + "/admin/recipe/search?search=" + search,
      options
    );
  }

  getRecipe(id) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/recipe/getadmin/" + id, options);
  }

  getUsers(search) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(
      this.url + "/admin/list_users?search=" + search,
      options
    );
  }

  deleteUsers(email) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/user/admin_delete_user",
      { email: email },
      options
    );
  }

  sendNotification(data) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(this.url + "/admin/sendnotification", data, options);
  }

  checkNotificationProgress() {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(this.url + "/admin/getnotificationprogress", options);
  }

  getUnassociatedIngredients(search, page, unassociatedOnly: boolean = true) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/admin/unassociated_ingredients",
      { search: search, page: page, unassociated_only: unassociatedOnly },
      options
    );
  }

  associateIngredient(ingredientId, adminIngredientId) {
    const headers = new Headers({ Authorization: this.getToken() });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(
      this.url + "/admin/associate_ingredient",
      {
        ingredient_id: ingredientId,
        ingredient_reference_id: adminIngredientId
      },
      options
    );
  }

  deleteNotifications() {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.post(
      // tslint:disable-next-line: quotemark
      this.url + "/admin/delete_notifications",
      {},
      options
    );
  }

  adminIngredientsStatistics() {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.get(
      // tslint:disable-next-line: quotemark
      this.url + "/statistics/admin_ingredients",
      options
    );
  }

  sendNotificationsNow() {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.get(
      // tslint:disable-next-line: quotemark
      this.url + "/admin/sendnotifications",
      options
    );
  }

  getShelves() {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.get(
      // tslint:disable-next-line: quotemark
      this.url + "/admin/shelves",
      options
    );
  }

  getAssociatedIngredients(id: number) {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.post(
      // tslint:disable-next-line: quotemark
      this.url + "/admin/associated_ingredients",
      { id: id },
      options
    );
  }

  getParentIngredients(id: number) {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.post(
      // tslint:disable-next-line: quotemark
      this.url + "/admin/parent_ingredients",
      { id: id },
      options
    );
  }

  getTags(from:String, to:String) {
    const headers = new Headers({ Authorization: this.getToken() });
    // tslint:disable-next-line: prefer-const
    let options = new RequestOptions({ headers: headers });
    return this.http.get(
      // tslint:disable-next-line: quotemark
      this.url + "/statistics/tags.json?from=" + from + "&to=" + to,
      options
    );
  }


}
