import { ApiService } from "./../api.service";
import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-notification-progress",
  templateUrl: "./notification-progress.page.html",
  styleUrls: ["./notification-progress.page.scss"]
})
export class NotificationProgressPage implements OnInit {
  constructor(private api: ApiService, private modalCtrl: ModalController) {}

  timer = null;
  message = "";

  ngOnInit() {
    // this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.api.checkNotificationProgress().subscribe(res => {
        console.log(res);
        if (res.json().message == null) {
          clearInterval(this.timer);
          this.modalCtrl.dismiss();
        } else {
          this.message = res.json().message;
        }
      });
    }, 1000);
  }

  ionViewDidLeave() {
    clearInterval(this.timer);
  }

  dismiss() {
    console.log("dismissing...");
    clearInterval(this.timer);
    this.modalCtrl.dismiss();
  }
}
