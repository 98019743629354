import { ApiService } from './api.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Tableau de bord',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Notifications',
      url: '/notifications',
      icon: 'notifications'
    },
    {
      title: 'Statistiques',
      url: '/statistics',
      icon: 'stats'
    },
    {
      title: 'Recettes',
      url: '/recipes',
      icon: 'pizza'
    },
    {
      title: 'Ingrédients',
      url: '/ingredients',
      icon: 'pricetag'
    },
    {
      title: 'Associations ingrédients',
      url: '/associations',
      icon: 'git-network'
    },
    {
      title: 'Utilisateurs',
      url: '/list-users',
      icon: 'people'
    },
    {
      title: 'QR Codes',
      url: '/tags',
      icon: 'pricetag'
    },
    {
      title: 'Login',
      url: '/login',
      icon: 'swap'
    },
    {
      title: 'Logout',
      url: '/login',
      icon: 'exit'
    },

  ];

  constructor(
    private api: ApiService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  logout() {
    this.api.logout();
  }
}
