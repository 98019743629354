import { ApiService } from './../api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Chart } from "chart.js";
interface Data {
  id: number;
  m: number;
  d: number,
  h: number,
  count: number;
}
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.page.html',
  styleUrls: ['./statistics.page.scss'],
})
export class StatisticsPage implements OnInit {
  @ViewChild("sessions", { read: ElementRef, static: false }) sessionCanvas: ElementRef;
  @ViewChild("recipeviews", { read: ElementRef, static: false }) recipeViewsCanvas;
  @ViewChild("registrations", { read: ElementRef, static: false }) registrationsCanvas;
  @ViewChild("leaves", { read: ElementRef, static: false }) leavesCanvas;
  @ViewChild("monthly_registrations", { read: ElementRef, static: false }) monthlyRegistrationsCanvas;
  @ViewChild("monthly_leaves", { read: ElementRef, static: false }) monthlyLeavesCanvas;


  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  sessionChart: any;
  recipeViewsChart: any;
  registrationsChart: any;
  leavesChart: any;
  monthlyRegistrationsChart: any;
  monthlyLeavesChart: any;

  ts = [];
  sessions = [];
  tsr = [];
  recipeViews = [];
  registrations = [];
  rsr = [];
  leaves = [];
  monthlyLeaveTimes = [];
  monthlyLeaveValues = [];
  lsr = [];
  monthlyRegistrationTimes = [];
  monthlyRegistrationValues = [];
  period = 1;

  getSessionData() {
    this.api.getSessions().subscribe(resp => {
      var d = resp.json().sessions;
      // console.log(d);
      d.map(dd => {
        this.ts.push(dd.ts);
        this.sessions.push(dd.c);
      });

      this.sessionChart = new Chart(this.sessionCanvas.nativeElement, {
        type: "bar",
        data: {
          labels: this.ts,
          datasets: [
            {
              label: "Sessions",
              data: this.sessions,
              borderWidth: 1,
              borderColor: "#0000e7",
              backgroundColor: "#00c8e7"
            }
          ]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day"
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  // suggestedMin: 20,
                  // suggestedMax: 35,
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
    });
  }

  getRecipeViewsData() {
    this.api.getRecipeViews().subscribe(resp => {
      var d = resp.json().recipes;
      d.map(dd => {
        this.tsr.push(dd.ts);
        this.recipeViews.push(dd.c);
      });
      this.recipeViewsChart = new Chart(this.recipeViewsCanvas.nativeElement, {
        type: "bar",
        data: {
          labels: this.tsr,
          datasets: [
            {
              label: "Recettes",
              data: this.recipeViews,
              borderWidth: 1,
              borderColor: "#0000e7",
              backgroundColor: "#0000e7"
            }
          ]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day"
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  // suggestedMin: 20,
                  // suggestedMax: 35,
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
    });
  }

  getRegistrationsData() {
    this.api.getRegistrations().subscribe(resp => {
      // console.log(resp);
      var d = resp.json().registrations;
      var dmonth = resp.json().monthly_registrations;
      // console.log(dmonth);
      d.map(dd => {
        this.rsr.push(dd.ts);
        this.registrations.push(dd.c);
      });
      dmonth.map(dd => {
        this.monthlyRegistrationTimes.push(dd.ts);
        this.monthlyRegistrationValues.push(dd.c);
      });
      this.registrationsChart = new Chart(this.registrationsCanvas.nativeElement, {
        type: "bar",
        data: {
          labels: this.rsr,
          datasets: [
            {
              label: "Inscriptions",
              data: this.registrations,
              borderWidth: 1,
              borderColor: "#00e3e7",
              backgroundColor: "#00e3e7"
            }
          ]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day"
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  // suggestedMin: 20,
                  // suggestedMax: 35,
                  // stepSize: 1,
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
      this.monthlyRegistrationsChart = new Chart(this.monthlyRegistrationsCanvas.nativeElement, {
        type: "bar",
        data: {
          labels: this.monthlyRegistrationTimes,
          datasets: [
            {
              label: "Inscriptions mensuelles",
              data: this.monthlyRegistrationValues,
              borderWidth: 1,
              borderColor: "#00e3e7",
              backgroundColor: "#00e3e7"
            }
          ]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "month",
                  // stepSize: 1
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  // suggestedMin: 20,
                  // suggestedMax: 35,
                  // stepSize: 1,
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });
    });
  }

  getLeavesData() {
    this.api.getLeaves().subscribe(resp => {
      // console.log(resp);
      var d = resp.json().leaves;
      d.map(dd => {
        this.lsr.push(dd.ts);
        this.leaves.push(dd.c);
      });
      let dmonth = resp.json().monthly_leaves;
      // console.log(dmonth);
      this.monthlyLeaveValues = [];
      dmonth.map(ddd => {
        this.monthlyLeaveTimes.push(ddd.ts);
        this.monthlyLeaveValues.push(ddd.c);
      });

      this.leavesChart = new Chart(this.leavesCanvas.nativeElement, {
        type: "bar",
        data: {
          labels: this.lsr,
          datasets: [
            {
              label: "Désinscriptions",
              data: this.leaves,
              borderWidth: 1,
              borderColor: "#e70000",
              backgroundColor: "#e70000"
            }
          ]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "day"
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  // suggestedMin: 20,
                  // suggestedMax: 35,
                  // stepSize: 1,
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });


      this.monthlyLeavesChart = new Chart(this.monthlyLeavesCanvas.nativeElement, {
        type: "bar",
        data: {
          labels: this.monthlyLeaveTimes,
          datasets: [
            {
              label: "Désinscription mensuelles",
              data: this.monthlyLeaveValues,
              borderWidth: 1,
              borderColor: "#e70300",
              backgroundColor: "#e70300"
            }
          ]
        },
        options: {
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  unit: "month",
                  // stepSize: 1
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  // suggestedMin: 20,
                  // suggestedMax: 35,
                  // stepSize: 1,
                  beginAtZero: true
                }
              }
            ]
          }
        }
      });

    });
  }

  ionViewWillEnter() {
    this.getSessionData();
    this.getRecipeViewsData();
    this.getRegistrationsData();
    this.getLeavesData();
  }

  refresh(event) {
    this.getSessionData();
    this.getRecipeViewsData();
    this.getRegistrationsData();
    this.getLeavesData();
    event.target.complete();
  }

}
