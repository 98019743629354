import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSource } from '@angular/cdk/table';
import { MatTableDataSource, MatSort, MatFormField } from '@angular/material';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
  selector: 'app-scan-views',
  templateUrl: './scan-views.component.html',
  styleUrls: ['./scan-views.component.scss'],
})

export class ScanViewsComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  scanColumnDefs = ['type', 'scans', 'email', 'utilisateur', 'os', 'compte créé'];
  scanData;
  dataSource;
  period: number;
  updating : boolean;
  displayedColumns= ["key", "scans", "pseudo", "email", "os"];
  periodValidator = new FormControl('', [Validators.required, Validators.min(1)]);

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.getScanStatistics();
    this.period = 1;
    this.updating = false;
  }


  getScanStatistics() {
    if (this.period < 1) this.period = 1;
    this.updating = true;
    this.api.getScanStatistics(this.period).subscribe(res => {
      this.scanData = res.json().by_users;
      this.dataSource = new MatTableDataSource(this.scanData);
      this.dataSource.sort = this.sort;
      console.log(this.dataSource);
      this.updating = false;
    });
  }

  getErrorMessage() {
    console.log(this.periodValidator);
    if (this.periodValidator.hasError('required')) {
      return 'Période ?';
    }

    return this.periodValidator.hasError('min') ? 'La période doit être > 1' : '';
  }
}

