import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EditIngredientPage } from './edit-ingredient/edit-ingredient.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule' },
  { path: 'statistics', loadChildren: './statistics/statistics.module#StatisticsPageModule' },
  { path: 'ingredients', loadChildren: './ingredients/ingredients.module#IngredientsPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'edit-ingredient/:id', loadChildren: './edit-ingredient/edit-ingredient.module#EditIngredientPageModule' },
  { path: 'recipes', loadChildren: './recipe/recipe.module#RecipePageModule' },
  { path: 'list-users', loadChildren: './list-users/list-users.module#ListUsersPageModule' },
  { path: 'notification-progress', loadChildren: './notification-progress/notification-progress.module#NotificationProgressPageModule' },
  { path: 'associations', loadChildren: './associations/associations.module#AssociationsPageModule' },
  { path: 'tags', loadChildren: './tags/tags.module#TagsComponentModule' },
  //{ path: 'edit-ingredient/:id', component: EditIngredientPage }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
