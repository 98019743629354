import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.page.html',
  styleUrls: ['./confirmation.page.scss'],
})
export class ConfirmationPage implements OnInit {
  @Input() msg: string
  @Input() error: string
  info = "";
  err = "";
  constructor(private modCtr: ModalController, private navParams: NavParams) {
    this.info = navParams.get('msg');
    this.err = navParams.get('error');
  }

  ngOnInit() {
  }

  dismiss() {
    this.modCtr.dismiss();
  }

}
